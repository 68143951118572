import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import VerifiedSmsService from '@/services/verified-sms.service'
import DateRange from '@/components/Graphs/DateRange/DateRange.vue'
import moment from 'moment'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import getEnv from '@/util/env'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'GoogleVerified',
  components: { HeaderTopDashboard, StatsTitle, DateRange, ButtonMultimedia, OverlayManager },
  data: function () {
    return {
      dataStoreUrls: {},
      show: false,
      globalControl: new GlobalControl(),
      loading: true,
      options: {},
      content: [],
      searchTerm: '',
      totalVerified: 0,
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      stepperStep: 1,
      editedItem: this.initVerified(),
      data: [],
      ready: true,
      billingProfile: false,
    }
  },
  methods: {
    initVerified () {
      return {
        id: '',
        name: '',
        description: '',
        logo: '',
        senders: '',
        company_employee_name: '',
        company_employee_email: '',
        partner_name: '360NRS',
        partner_email: 'verified.sms@360nrs.com',
      }
    },
    isValidLogo () {
      return this.editedItem.logo !== '' && this.editedItem.logo !== 'undefined'
    },
    dateFormat (date) {
      return moment(String(date)).format('YYYY/MM/DD')
    },
    initialize (data) {
      this.content = data.map((x) => {
        return {
          id: x.id,
          name: x.name,
          description: x.description,
          logo: x.logo,
          senders: x.senders,
          company_employee_name: x.company_employee_name,
          company_employee_email: x.company_employee_email,
          partner_name: x.partner_name,
          partner_email: x.partner_email,
          valid: x.valid,
        }
      })
    },
    setThumbnail (url) {
      this.editedItem.logo = getEnv('VUE_APP_ASSETS_URL') + url
    },
    save () {
      if (this.editedItem.id === '') {
        this.loading = true
        VerifiedSmsService.postDataForm(this.editedItem)
          .then(
            (response) => {
              this.resetForm()
              this.getDataFromApi()
            },
            () => { },
          )
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = true
        VerifiedSmsService.updateDataForm(this.editedItem)
          .then(
            (response) => {
              this.getDataFromApi()
            },
            () => { },
          )
          .finally(() => {
            this.loading = false
          })
      }
      this.closeOverlay()
      this.$emit('close')
    },

    getDataFromApi () {
      EventBus.$emit('showLoading', true)
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      this.content = []
      this.loading = true
      VerifiedSmsService.getData(params)
        .then(
          (response) => {
            this.data = JSON.stringify(response.verifiedSmsDataTable.data)
            this.initialize(response.verifiedSmsDataTable.data)
            this.itemsPerPage = parseInt(response.verifiedSmsDataTable.per_page)
            this.page = response.verifiedSmsDataTable.current_page
            this.totalVerified = response.verifiedSmsDataTable.total
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
    deleteItem (item) {
      this.editedIndex = this.content.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.content.splice(this.editedIndex, 1)
      this.loading = true
      VerifiedSmsService.deleteDataForm(this.editedItem.id)
        .then(
          (response) => {
            this.getDataFromApi()
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  computed: {
    headers () {
      return [
        { text: this.$t('Id'), align: 'start', value: 'id' },
        { text: this.$t('Nombre'), align: 'start', value: 'name' },
        { text: this.$t('Descripción'), align: 'start', value: 'description' },
        { text: this.$t('Empleado'), align: 'start', value: 'company_employee_name' },
        { text: this.$t('Partner'), align: 'start', value: 'partner_name' },
        { text: this.$t('Estado'), align: 'start', value: 'valid' },
        { text: '', value: 'actions', sortable: false },
      ]
    },
    formTitle () {
      return this.editedIndex === -1 ? this.$t('Solicitud verified SMS') : this.$t('Editar solicitud verified SMS')
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
  },
  watch: {
    globalControl: {
      handler: function () {
        this.getDataFromApi()
      },
      deep: true,
    },
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  created () {
  },
  mounted () {

  },
}
