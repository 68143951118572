import { Service } from './service'

const PATH = 'verified-sms'

class VerifiedSmsService extends Service {
  getData (params = {}) {
    return this.get(PATH + '', params)
  }

  getDataForm (requestId, params = {}) {
    return this.get(PATH + '/edit/' + requestId, params)
  }

  postDataForm (params = {}) {
    return this.post(PATH + '/create', params)
  }

  updateDataForm (params = {}) {
    return this.post(PATH + '/update', params)
  }

  deleteDataForm (params = {}) {
    return this.post(PATH + '/delete/' + params)
  }

  renameDataForm (params = {}) {
    return this.post(PATH + '/update', params)
  }

  getVerifiedBrands (params = {}) {
    return this.get(PATH + '/verified-brands', params)
  }

  getVerifiedSenders (params = {}) {
    return this.get(PATH + '/verified-senders', params)
  }
}

export default new VerifiedSmsService()
